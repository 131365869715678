<template>
	<div class="">
		<a-spin :spinning="loading">
			<div class="flex space alcenter">
				<div class="ft20 cl-main ftw600">{{getTitle}}</div>
				<a-button @click="$router.go(-1)">返回</a-button>
			</div>
			
			<div class="mt20 bg-w pd40">
				<div style="width: 1000px;">
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
							
							<a-form-model-item label="选择项目" required>
								<div v-if="JSON.stringify(form.project) == '{}'" @click="chooseProjectAct()" class="edit-snap-up-project-it ml10 clickAct flex center alcenter cl-theme ft16 ftw600">
									选择<i class="iconfont iconicon_arrowr ft16 ml5"/>
								</div>
								
								<div v-else class="edit-snap-up-project-not-it flex space">
									<div style="width: 339px;border-right: 1px solid #DCDFE6;" class="flex">
										<img  :src="form.project.cover_img"/>
										<div class="ml10">
											<div class="ft14 ftw400 cl-black text-over4">{{form.project.abbreviation}}</div>
											<div class="ft12 ftw400 cl-notice mt15 ">门市原价：¥{{form.project.market_price}}</div>
										</div>
									</div>
									<div class="flex alcenter cente ft16 ftw600 cl-theme clickAct" @click="chooseProjectAct()">
										重新选择<i class="iconfont iconicon_arrowr ft16 ml5"/>
									</div>
								</div>
							</a-form-model-item>
							
							<a-form-model-item label="会员价" required>
								<a-input-number v-model="form.vip_price" :formatter="value => `${value} 元`" :min="0" :precision="2" placeholder="请输入" style="width: 160px;" />
							</a-form-model-item>
							
							<a-form-model-item required label="是否限时秒杀">
								<a-radio-group v-model="form.is_flash_sale">
									<a-radio :value="1">是</a-radio>
									<a-radio :value="0">否</a-radio>
								</a-radio-group>
							</a-form-model-item>
							
							<a-form-model-item label="秒杀价格" required v-if="form.is_flash_sale==1">
								<a-input-number v-model="form.flash_price" :formatter="value => `${value} 元`" :min="0" :precision="2" placeholder="请输入" style="width: 160px;" />
							</a-form-model-item>
							
							<a-form-model-item label="秒杀配额" required v-if="form.is_flash_sale==1">
								<a-input-number v-model="form.flash_num" :formatter="value => `${value} 个`" :min="0" :precision="0" placeholder="请输入" style="width: 160px;" />
							</a-form-model-item>
							
							<a-form-model-item label="秒杀时间" required v-if="form.is_flash_sale==1">
								<a-range-picker @change="changeFlashSaleTime" v-model="form.flash_sale_time"  format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" />
							</a-form-model-item>
							
							<a-form-model-item label="有效期" required >
								<div style="position: relative;width:210px;" class="flex alcenter">
									<a-input-number style="width:210px;" v-model="form.valid_time" :precision="0" :min="0" >
										
									</a-input-number>
									<a-select v-model="form.valid_unit" style="width: 70px" class="edit-snap-up-unit-addon">
										<a-select-option :value="'day'">
											天
										</a-select-option>
										<a-select-option :value="'month'">
											月
										</a-select-option>
										<a-select-option :value="'year'">
											年
										</a-select-option>
									</a-select>
								</div>
							</a-form-model-item>
							
							<a-form-model-item label="展示已售的数量" required >
								<a-input-number :formatter="value => `${value} 个`" v-model="form.fictitious_take_count" :min="0" :precision="0"
								 placeholder="请输入" />
							</a-form-model-item>
							
							<a-form-model-item label="排序" required help="数字越高,排的越高" >
								<a-input-number  v-model="form.sort" :min="0" :precision="0"
								 placeholder="请输入" />
							</a-form-model-item>
							
							<a-form-model-item required label="是否只有新用户才可以购买" help="注册一周内未购买任何抢购的用户视为新用户" >
								<a-radio-group v-model="form.is_new_can">
									<a-radio :value="1">是</a-radio>
									<a-radio :value="0">否</a-radio>
								</a-radio-group>
							</a-form-model-item>
							
							<a-form-model-item label="单用户可以购买的数量" required help="0表示不限制" >
								<a-input-number  v-model="form.can_buy_num" :min="0" :precision="0"
								 placeholder="请输入" />
							</a-form-model-item>
							
							
							<a-form-model-item required label="详情页轮播图" >
								<div class="ft12 ftw400 cl-notice">最多可传5张，建议上传750x750px</div>
								<div class="mt10 ml20">
									<upload-list v-model="form.rotation" :num="5"></upload-list>
								</div>
							</a-form-model-item>
							
							<div class="edit-snap-up-line mb40"></div>
							
							<a-form-model-item label="商品详情" required class="mt20">
								<html-edit v-model="form.detail"></html-edit>
							</a-form-model-item>
							
							<div class="edit-snap-up-line"></div>
							
							<a-form-model-item label="购买须知" required>
								<div class="ft12 ftw400 cl-notice">不填就显示默认内容，可修改</div>
								
								<a-textarea  v-model="form.purchase_information" :rows="11" />
							</a-form-model-item>
							
							<a-form-model-item :wrapper-col="{ span: 2, offset: 1 }" class="mt20">
								<a-button type="primary" @click="onSubmit">
									保存上架
								</a-button>
						
							</a-form-model-item>
						</a-form-model>
					
				</div>
				
				<div v-if="chooseProjectVisible">
					<choose-project v-model="form.project" :visible="chooseProjectVisible" @cancel="cancelChooseProject" @ok="okChooseProject"></choose-project>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import uploadList from '../../components/upload/list.vue';
	import uploadImg from '../../components/upload/upload.vue';
	import chooseProject from './components/EditSnapup/modal/chooseProject.vue';
		import  htmlEdit from '../../components/html.vue';
	export default{
		components:{
			uploadList,
			uploadImg,
			chooseProject,
			htmlEdit
		},
		data(){
			return {
				loading:false,
				confirmLoading:false,
				chooseProjectVisible:false,
				labelCol: { span: 5 },
			    wrapperCol: { span: 19 },
				snap_up_id:0,
				form: {
					project:{},
					vip_price:'',
					is_flash_sale:0,
					flash_price:'',
					flash_num:'',
					flash_sale_time:[],
					valid_time:'',
					valid_unit:'day',
					fictitious_take_count:'',
					sort:'',
					is_new_can:0,
					can_buy_num:0,
					rotation:[],
					detail:'',
					purchase_information:'',
				}
			}
		},
		created() {
			if (!this.$route.query.id) {
				this.snap_up_id=0;
			} else {
				this.snap_up_id = parseInt(this.$route.query.id);
			}
			this.loaddata();
		},
		computed:{
			getTitle(){
				if(this.snap_up_id==0){
					return '添加抢购';
				}else{
					return '编辑抢购';
				}
			}
		},
		methods:{
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showSnapup',{
					snap_up_id:this.snap_up_id
				}).then(res=>{
					this.loading=false;
					if(res.detail){
						this.form=res.detail;
					}
				}).catch(res=>{
					console.log(res);
					this.loading=false;
					this.$router.go(-1);
				})
			},
			
			changeFlashSaleTime(value){
				this.form.flash_sale_time=value;
			},
			
			onSubmit() {
				if(JSON.stringify(this.form.project) == '{}'){
					this.$message.error('请选择项目');
					return;
				}
				
				if(!this.form.project.project_id){
					this.$message.error('请选择项目');
					return;
				}
				
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/saveSnapup',{
					snap_up_id:this.snap_up_id,
					project_id:this.form.project.project_id,
					vip_price:this.form.vip_price,
					is_flash_sale:this.form.is_flash_sale,
					flash_price:this.form.flash_price,
					flash_num:this.form.flash_num,
					flash_sale_time:this.form.flash_sale_time,
					valid_time:this.form.valid_time,
					valid_unit:this.form.valid_unit,
					fictitious_take_count:this.form.fictitious_take_count,
					sort:this.form.sort,
					is_new_can:this.form.is_new_can,
					can_buy_num:this.form.can_buy_num,
					rotation: this.form.rotation,
					detail:this.form.detail,
					purchase_information:this.form.purchase_information,
				}).then(res=>{
					this.$message.success('保存成功');
					this.$router.go(-1);
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			
			chooseProjectAct(){
				this.chooseProjectVisible=true;
			},
			cancelChooseProject(){
				this.chooseProjectVisible=false;
			},
			okChooseProject(){
				this.chooseProjectVisible=false;
			},
			
			
			addContent() {
				this.form.snap_up_details.push({
					type: 'content',
					content: ''
				});
			},
			
			addPic() {
				this.form.snap_up_details.push({
					type: 'pic',
					content: ''
				});
			},
			
			removeItem(index) {
				this.form.snap_up_details.splice(index, 1);
			},
			
			arrowUp(index){
			    let content  = JSON.parse(JSON.stringify(this.form.snap_up_details));
			    let thisData= JSON.parse(JSON.stringify(content[index]));
			    let upData= JSON.parse(JSON.stringify(content[index-1]));
			    content[index]=upData;
			    content[index-1]=thisData;
			    this.form.snap_up_details = content;
			},
			
			arrowDown(index){
			    let content  = JSON.parse(JSON.stringify(this.form.snap_up_details));
			    let thisData= JSON.parse(JSON.stringify(content[index]));
			    let downData= JSON.parse(JSON.stringify(content[index+1]));
			    content[index]=downData;
			    content[index+1]=thisData;
			    this.form.snap_up_details = content;
			    
			},
			
		}
	}

</script>

<style>
	.edit-snap-up-project-it{
		width: 400px;
		height: 100px;
		background: #FFFFFF;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
	}
	
	.edit-snap-up-project-not-it{
		width: 480px;
		height: 100px;
		background: #FFFFFF;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		padding: 20px;
		line-height: 20px;
	}
	
	.edit-snap-up-project-not-it img{
		width: 60px;
		height: 60px;
	}
	
	.edit-snap-up-unit-addon{
		position: absolute;
		right: 0px;
	}
	
	.edit-snap-up-line{
		width: 100%;
		height: 1px;
		background: #EBEDF5;
	}
	
	.edit-snap-up-details-text {
		width: 120px;
		height: 40px;
		border-radius: 4px;
		border: 1px solid #5333FF;
	}
	
	.mobile-content{
		width: 750px;
	}
	.mobile-content{
		
	}
	
	.mobile-item{
		position: relative;
	}
	.mobile-item .action{
		position: absolute;
		left: 0;
		top: -15px;
		width: 100%;
		z-index: 2;
	}
	.del-action{
		width: 30px;
		height: 30px;
		background: rgba(0,0,0,.5);
		text-align: center;
		line-height: 30px;
		border-radius: 15px;
		color:#FFFFFF;
		position: absolute;
		right: -15px;
		top:-15px;
		z-index: 3;
		cursor: pointer;
	}
	.up-action,.down-action{
		width: 30px;
		height: 30px;
		background: #FFFFFF;
		text-align: center;
		line-height: 30px;
		border-radius: 15px;
		color:#007AFF;
		box-shadow:0px 2px 8px 0px rgba(0,0,0,0.08);
	
	}
</style>